import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import styled from "styled-components";
import Heading from "../../Typography/Heading";
import Section from "../../layout/Sections/Section";

const MockStyle = styled.div`
  ${props => props.mockStyle}
`;

const HeadingStyle = styled.div`
  ${props => props.headingStyle}
`;

const Mock = ({background, image, title, titleTag, mockStyle, headingStyle, headingType}) => (
  <section className="background-helper">
    <div className="background-full">
      <Img className='image' fluid={background} />
    </div>
    <Section sectionTag="div" modifiers={['mock', 'padding-x']}>
      <HeadingStyle headingStyle={headingStyle} type={headingType}>
        <Heading title={title} size="medium-2x" titleTag={titleTag} />
      </HeadingStyle>
    </Section>
    <MockStyle mockStyle={mockStyle}>
      <Img className='image' fluid={image} />
    </MockStyle>
  </section>
);

Mock.propTypes = {
  background: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default Mock;
